import { RouteRecordRaw } from 'vue-router';
import { ProxyRouter } from './proxy';

import History from '@/components/pageHistory.vue';

// meta 中 user的tab表示需要等陆
const routes: RouteRecordRaw[] = [
	{
		path: '/',
		name: 'Default',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/cosark/login/newLogin.vue'),
	},
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/404.vue'),
	},
	
	{
		path: '/cosark/productDetail',
		name: 'ProductDetail',
		meta: {
		  fullName: '产品详细',
		  needLogin: true
		},
		components: {
		  default: () => import('@/views/main/product/detail.vue'),
		},
		
	},
	{
		
		path: '/cosark/v3DetailImageEdit',
		name: 'V3DetailImageEdit',
		meta: {
		  fullName: '详细图片定制',
		  needLogin: true
		},
		components: {
		  default: () => import('@/views/main/v3Product/v3DetailImageEdit.vue'),
		},
	}, {
		path: '/cosark/exploreSeries',
		name: 'ExploreSeries',
		meta: {
			fullName: '主题系列',
			needLogin: true
		},
		components: {
			default: () => import('@/views/main/explore/exploreSeries.vue'),
		},
	}
	,
	{
		path: '/needCeoHelp',
		name: 'NeedCeoHelp',
		meta: {
		  fullName: '求助CEO',
		  needLogin: true
		},
		components: {
		  default: () => import('@/views/needCeo/index.vue'),
		},
	},
	
	{
		path: '/cosark',
		name: 'cosark', 
		component: () => import('@/views/main/index.vue'),
		children: [
			{
				path: 'home',
				name: 'Home',
				meta: {
				  fullName: '首页',
		  			needLogin: true
				},
				components: {
				  default: () => import('@/views/main/home.vue'),
				},
			},{
				path: 'eryuan',
				name: 'Eryuan',
				meta: {
				  fullName: '创意二元',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/eryuan/index.vue'),
				},
			},{
				path: 'product',
				name: 'Product',
				meta: {
				  fullName: '产品魔方',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/product/index.vue'),
				},
			},{
				path: 'productList',
				name: 'ProductList',
				meta: {
				  fullName: '产品列表',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/product/list.vue'),
				},
			},{
				path: 'ai',
				name: 'AI',
				meta: {
				  fullName: 'Ai-定制',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/ai/index.vue'),
				},
			},{
				path: 'ai-generate',
				name: 'AIGenerate',
				meta: {
					fullName: 'AI生成',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/ai/generate.vue'),
				}
			},{
				path: 'ai-generate-result',
				name: 'AIGenerateResult',
				meta: {
					fullName: 'AI生成结果',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/ai/generateResult.vue'),
				}
			},{
				path: 'customTwo',
				name: 'AICustomTwo',
				meta: {
				  fullName: 'Ai-定制选类别',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/ai/customTwo.vue'),
				},
			},{
				path: 'customThree',
				name: 'AICustomThree',
				meta: {
				  fullName: 'Ai-定制选类别',
				  needLogin: true
				},
				components: {
					
				  default: () => import('@/views/main/ai/customThree.vue'),
				},
			},{
				path: 'customFeature',
				name: 'AICustomFeature',
				meta: {
				  fullName: 'Ai-定制选类别',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/ai/customFeature.vue'),
				},
			},{
				path: '/cosark/customPacking',
				name: 'AICustomPacking',
				meta: {
				  fullName: '定制包材',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/ai/customPacking.vue'),
				},
			},{
				path: '/cosark/createScheme',
				name: 'AICreateScheme',
				meta: {
				  fullName: 'AI生成方案',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/ai/createScheme.vue'),
				},
			},{
				path: '/cosark/customMore',
				name: 'AICustomMore',
				meta: {
				  fullName: 'AI方案更多定制',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/ai/customMore.vue'),
				},
			},{
				path: 'admin',
				name: 'Admin',
				meta: {
				  fullName: '个人中心',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/admin/index.vue'),
				},
			},{
				path: 'bref',
				name: 'Bref',
				meta: {
				  fullName: '个人中心-Bref详细',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/admin/bref/bref.vue'),
				},
			},{
				path: 'schemeDetail',
				name: 'SchemeDetail',
				meta: {
				  fullName: '个人中心-需求详细',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/admin/schemeDetail/schemeDetail.vue'),
				},
			},{
				path: 'explore',
				name: 'Explore',
				meta: {
				  fullName: '探索',
				  needLogin: true
				},
				components: {
					header: () => import('@/components/pageHeader.vue'),
				  default: () => import('@/views/main/explore/index.vue'),
				},
			
			// },{
			// 	path: 'singleProductSeries',
			// 	name: 'SingleProductSeries',
			// 	meta: {
			// 	  fullName: '单品系列',
			// 	  needLogin: true
			// 	},
			// 	components: {
			// 	  default: () => import('@/views/main/explore/singleProductSeries.vue'),
			// 	},
			},{
				path: 'v3Product',
				name: 'V3Product',
				meta: {
				  fullName: '产品',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/v3Product/index.vue'),
				},
			},{
				path: 'v3Detail',
				name: 'V3Detail',
				meta: {
				  fullName: '白牌详细',
				  needLogin: true
				},
				components: {
				  default: () => import('@/views/main/v3Product/v3Detail.vue'),
				},
			}, {
				path: 'category',
				name: 'Category',
				meta: {
					fullName: '类别',
					needLogin: true
				},
				components: {
					header: () => import('@/components/pageHeader.vue'),
					default: () => import('@/views/main/category/index.vue')
				}
			}, {
				path: 'secondCategory',
				name: 'SecondCategory',
				meta: {
					fullName: '二级类别',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/category/secondCategory.vue')
				}
			}, {
				path: 'categoryDetail',
				name: 'CategoryDetail',
				meta: {
					fullName: '类别详情',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/category/detail.vue')
				}
			},{
				path: 'v3Admin',
				name: 'V3Admin',
				meta: {
					fullName: '个人中心',
					needLogin: true
				},
				component: () => import('@/views/main/v3Admin/index.vue'),
			}
			,{
				path: 'collection',
				name: 'Collection',
				meta: {
					fullName: '收藏',
					needLogin: true
				},
				components: {
					header: () => import('@/components/pageHeader.vue'),
					default: () => import('@/views/main/notStarted.vue')
				}
			}
			,{
				path: 'creation',
				name: 'Creation',
				meta: {
					fullName: '创作',
					needLogin: true
				},
				components: {
					header: () => import('@/components/pageHeader.vue'),
					default: () => import('@/views/main/notStarted.vue')
				}
			}
			,{
				path: 'whiteCard',
				name: 'WhiteCard',
				meta: {
					fullName: '白牌',
					needLogin: true
				},
				components: {
					header: () => import('@/components/pageHeader.vue'),
					default: () => import('@/views/main/whiteCard/index.vue')
				}
			}
			,{
				path: 'whiteCardCategory',
				name: 'WhiteCardCategory',
				meta: {
					fullName: '白牌分类',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/template/category/index.vue')
				}
			}
			,{
				path: 'v3WhiteCardDetail',
				name: 'V3WhiteCardDetail',
				meta: {
					fullName: '白牌详细',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/template/detail/index.vue')
				}
			}
			,{
				path: 'packagingCategory',
				name: 'PackagingCategory',
				meta: {
					fullName: '包材分类',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/template/category/index.vue')
				}
			}
			,{
				path: 'v3PackagingDetail',
				name: 'V3PackagingDetail',
				meta: {
					fullName: '包材详细',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/template/detail/index.vue')
				}
			}
			,{
				path: 'contentsCategory',
				name: 'ContentsCategory',
				meta: {
					fullName: '配方分类',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/template/category/index.vue')
				}
			}
			,{
				path: 'v3ContentsDetail',
				name: 'V3ContentsDetail',
				meta: {
					fullName: '配方详细',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/template/detail/index.vue')
				}
			}
			,{
				path: 'v3SchemeDetail',
				name: 'V3SchemeDetail',
				meta: {
					fullName: '定制详细',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/template/detail/index.vue')
				}
			}
			,{
				path: 'secondaryCategory',
				name: 'SecondaryCategory',
				meta: {
					fullName: '二级分类',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/template/category/index.vue')
				}
			}
			,{
				path: 'broadcastCategory',
				name: 'BroadcastCategory',
				meta: {
					fullName: '直播分类',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/template/category/index.vue')
				}
			}
			,{
				path: 'broadcast',
				name: 'Broadcast',
				meta: {
					fullName: '直播',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/broadcast/index.vue')
				}
			}
			,{
				path: 'openProducts',
				name: 'OpenProducts',
				meta: {
					fullName: '开品',
					needLogin: true
				},
				components: {
					header: () => import('@/components/pageHeader.vue'),
					default: () => import('@/views/main/openProducts/index.vue')
				}
			}
			,{
				path: 'recipe',
				name: 'Recipe',
				meta: {
					fullName: '配方',
					needLogin: true
				},
				components: {
					header: () => import('@/components/pageHeader.vue'),
					default: () => import('@/views/main/notStarted.vue')
				}
			}
			,{
				path: 'account',
				name: 'Account',
				meta: {
					fullName: '账户',
					needLogin: true
				},
				components: {
					header: () => import('@/components/pageHeader.vue'),
					default: () => import('@/views/main/v3Admin/account.vue')
				}
			}
			,{
				path: 'partner',
				name: 'Partner',
				meta: {
					fullName: '合作伙伴',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/v3Admin/template.vue')
				}
			}
			,{
				path: 'customer',
				name: 'Customer',
				meta: {
					fullName: '我的客户',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/v3Admin/template.vue')
				}
			}
			,{
				path: 'create/bigCategory',
				name: 'CreateBigCategory',
				meta: {
					fullName: '创作大类',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/bigCategory.vue')
				}
			}
			,{
				path: 'create/smallCategory',
				name: 'CreateSmallCategory',
				meta: {
					fullName: '创作小类',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/smallCategory.vue')
				}
			}
			,{
				path: 'create/firstSteps',
				name: 'CreateFirstSteps',
				meta: {
					fullName: '创作第一步',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/templateSteps.vue')
				}
			}
			,{
				path: 'create/secondSteps',
				name: 'CreateSecondSteps',
				meta: {
					fullName: '创作第二步',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/templateSteps.vue')
				}
			}
			,{
				path: 'create/thirdSteps',
				name: 'CreateThirdSteps',
				meta: {
					fullName: '创作第三步',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/templateSteps.vue')
				}
			}
			,{
				path: 'create/categorySteps',
				name: 'CreateCategorySteps',
				meta: {
					fullName: '创作选择类别',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/categorySteps.vue')
				}
			}
			,{
				path: 'create/whiteCardLink',
				name: 'CreateWhiteCardLink',
				meta: {
					fullName: '白牌定制',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/templateThreeLink.vue')
				}
			}
			,{
				path: 'create/customWay',
				name: 'CreateCustomWay',
				meta: {
					fullName: '选择定制方式',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/customTemplate.vue')
				}
			}
			,{
				path: 'create/customLogo',
				name: 'CreateCustomLogo',
				meta: {
					fullName: '选择定制Logo',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/customTemplate.vue')
				}
			}
			,{
				path: 'create/customOrder',
				name: 'CreateCustomOrder',
				meta: {
					fullName: '选择定制订单',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/customTemplate.vue')
				}
			}
			,{
				path: 'create/customMoreInfo',
				name: 'CreateCustomMoreInfo',
				meta: {
					fullName: '选择定制更多',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/customTemplate.vue')
				}
			}
			,{
				path: 'create/marketLetter',
				name: 'CreateMarketLetter',
				meta: {
					fullName: '市场信息',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/marketLetter.vue')
				}
			}
			,{
				path: 'create/recommendedProductSolution',
				name: 'CreateRecommendedProductSolution',
				meta: {
					fullName: '推荐产品方案',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/recommendedProductSolution.vue')
				}
			}
			,{
				path: 'create/openproductLink',
				name: 'CreateOpenproductLink',
				meta: {
					fullName: '开品定制',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/templateThreeLink.vue')
				}
			}
			,{
				path: 'create/whiteCardFinalSteps',
				name: 'CreateWhiteCardFinalSteps',
				meta: {
					fullName: '白牌提交方案',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/finalSteps.vue')
				}
			}
			,{
				path: 'create/openProductFinalSteps',
				name: 'CreateOpenProductFinalSteps',
				meta: {
					fullName: '开品提交方案',
					needLogin: true
				},
				components: {
					default: () => import('@/views/main/create/finalSteps.vue')
				}
			}
			,{
				path: 'notStarted',
				name: 'NotStarted',
				meta: {
					fullName: '个人中心',
					needLogin: true
				},
				components: {
					header: () => import('@/components/pageHeader.vue'),
					default: () => import('@/views/main/notStarted.vue')
				}
			}
		]
	}
];

const router = new ProxyRouter(routes).router;

export default router;
