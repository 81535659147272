import { $axios, FileResponseType } from "@/http/api";

class v3Needs {
  /**
   * v3模块数据
   */
  /**
   * 上传图片文件流
   */
  uploadFile<T>(data) {
    return $axios.Post<T, FileResponseType.Other>({
        url: "/api/eryuan/cosark.app/file/public/upload",
        data,
        headers: {
          "Content-Type": "multipart/form-data"
        }
    });
  }
  /**
   * 创建方案
   * @param data 
   * @returns 
   */
  createScheme<T>(data) {
    return $axios.Post<T, FileResponseType.Other>({
        url: "/api/eryuan/cosark.app/base/white/card/scheme",
        data,
    });
  }
  /**
   * 修改方案
   * @param data 
   * @returns 
   */
  changeScheme<T>(id, data) {
    return $axios.Put<T, FileResponseType.Other>({
        url: "/api/eryuan/cosark.app/person/center/white/card/scheme/" + id,
        data,
    });
  }

  /**
   * 方案列表
   * @returns 
   */
  schemeList<T>(params) {
    return $axios.Get<T, FileResponseType.Other>({
      url: "/api/eryuan/cosark.app/person/center/white/card/scheme/page",
      params
    });
  }

  /**
   * 方案详细
   * @returns 
   */
  schemeDetail<T>(id) {
    return $axios.Get<T, FileResponseType.Other>({
      url: `/api/eryuan/cosark.app/person/center/white/card/scheme/${id}`
    });
  }

  /**
   * 删除方案
   * @returns 
   */
  deletesScheme<T>(id) {
    return $axios.Delete<T, FileResponseType.Other>({
      url: `/api/eryuan/cosark.app/person/center/white/card/scheme/${id}`
    });
  }

  /**
   * 添加收藏产品
   * @param data 
   * @returns 
   */
  addFavoriteWhiteCard<T>(data) {
    return $axios.Post<T, FileResponseType.Other>({
        url: "/api/eryuan/cosark.app/favorites/white/card",
        data,
    });
  }
  /**
   * 添加包材
   * @param data 
   * @returns 
   */
  addFavoritePacking<T>(data) {
    return $axios.Post<T, FileResponseType.Other>({
        url: "/api/eryuan/cosark.app/favorites/packing",
        data,
    });
  }
  /**
   * 添加内容物
   * @param data 
   * @returns 
   */
  addFavoriteContent<T>(data) {
    return $axios.Post<T, FileResponseType.Other>({
        url: "/api/eryuan/cosark.app/favorites/sample",
        data,
    });
  }

  /**
   * 添加收藏方案
   * @param data 
   * @returns 
   */
  addFavoriteScheme<T>(data) {
    return $axios.Post<T, FileResponseType.Other>({
        url: "/api/eryuan/cosark.app/favorites/white/card/scheme",
        data,
    });
  }

  /**
   * 生成二维码
   * @param data 
   * @returns 
   */
  requestWXForGetUnlimited<T>(data) {
    return $axios.Post<T, FileResponseType.Other>({
        url: "/api/cosark/wss/wx/requestWXForGetUnlimited?_anonymous",
        data,
    });
  }

  /**
   * 收藏列表
   * @returns 
   */
  favoritesList<T>(params) {
    return $axios.Get<T, FileResponseType.Other>({
      url: "/api/eryuan/cosark.app/favorites/page",
      params
    });
  }

  /**
   * 收藏详细
   * @returns 
   */
  favoritesDetail<T>(id, params) {
    return $axios.Get<T, FileResponseType.Other>({
      url: `/api/eryuan/cosark.app/favorites/${id}`,
      params
    });
  }

  /**
   * 取消收藏 /api/eryuan/cosark.app/favorites/{id}
   */
  cancelFavorites<T>(id, params) {
    return $axios.Put<T, FileResponseType.Other>({
      url: `/api/eryuan/cosark.app/favorites/${id}`,
      params
    });
  }

  /**
   * 用户
   */
  v3AdminInfo<T>() {
    return $axios.Get<T, FileResponseType.Other>({
      url: `/api/cosark/wss/wechat/currUser`
    });
  }
  
  /**
   * 用户
   */
  WhiteCardAllPages<T>(params) {
    return $axios.Get<T, FileResponseType.Other>({
      url: `/api/eryuan/cosark.app/base/white/card/page`,
      params
    });
  }

  /**
   * refresh token ?appid=APPID&grant_type=refresh_token&refresh_token=REFRESH_TOKEN
   */
  refreshToken<T>(params) {
    return $axios.Get<T, FileResponseType.Other>({
      url: `https://api.weixin.qq.com/sns/oauth2/refresh_token`,
      params
    });
  }
}

/**
 * V3版本
 */
export const cosarkV3Needs = new v3Needs();
