/**
 * 项目中cookie 存储的字段枚举
 */
export enum LocakCookieKeyType {}

/**
 * 项目中Stroage 存储的字段枚举
 */
export enum LocakStorageKeyType {
    /**
     * 登录的token
    */
    Token = 'token',

    /**
     * 路由导航的堆栈
    */
    Historys = 'historys',

    /**
     * 当前模式
    */
    Mode = 'Mode',
    /**
     * 是否来自bref的后退
     */
    IsBackBrief = 'IsBackBrief',

    /**
     * 用户类被
     */
    UserModeType = 'UserModeType',

    /**
     * 登录页面 tab
     */
    LoginTabValue = 'LoginTabValue',
    /**
     * 是否同意协议
     */
    HasAggree = 'HasAggree',

    /**
     * 是否存在之前登录的手机号码
     */
    LoginPhoneNumber = 'LoginPhoneNumber',

    /**
     * logo信息
     */
    LogoInfomation = 'LogoInfomation',

    /**
     * 用户
     */
    IsComeFromCustomeOrder = 'IsComeFromCustomeOrder'
}


