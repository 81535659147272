import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
  RouteRecordRaw,
} from 'vue-router';
import { useRouteStoreSetup, useUserStoreSetup, useUserInfoSetup } from '@/stores';
import { LocakStorageKeyType } from './../utils/localStorageSaveKey';
import HistoryTitle from '@/components/pageHistory.vue';
import Xx from '../views/xx.vue';
import { ElMessageBox } from 'element-plus';
import { utils } from '@/utils/utils';
const { returnObjToken } = utils();
// const HomeView = () => h('div', 'HomePage')

/**
 * 路由守卫
 */
export class ProxyRouter {
  router: Router;

  constructor(routes: RouteRecordRaw[]) {
    //   初始化路由
    this.router = createRouter({
      history: createWebHistory(import.meta.env.VITE_BASE_ROUTE),
      routes,
    });
    this.router.onError(error => {
      // alert(error)
      if(error?.message.includes('Failed to fetch dynamically imported module')){
        ElMessageBox.alert('当前系统已经更新，请您点击按钮进行更新！', '温馨提示', {
          autofocus: false,
          confirmButtonText: '确定更新',
          showClose: false,
          callback: () => {
            location.reload();
          },
        })
      } 
    })
    // 塞入页面拦截
    this.router.beforeEach(this.beforeEach.bind(this));
    this.router.afterEach(this.afterEach.bind(this));
  }

  /**
   * 页面跳转前的拦截
   */
  async beforeEach(to: RouteLocationNormalized, form: RouteLocationNormalized, next: NavigationGuardNext) {
    const userStoreSetup = useUserStoreSetup();
    const { router } = this;
    const useInfoSetup =  useUserInfoSetup()
    console.log(import.meta.env.VITE_USER_NODE_ENV === 'dev')
    /**
     * 开发环境
     */
    if(!to.name) {
      next(
        {
          path: '/404'
        }
      )
    } else if(import.meta.env.VITE_USER_NODE_ENV === 'dev') {
      next()
    } else {
      if(to.meta.needLogin) { 
        if(returnObjToken().token){
          next()
        } else {
          next(
            {
              path: '/'
            }
          )
        }
      }  else {
        next()
      }
    }
    }

  afterEach(to: RouteLocationNormalized) {
    const routeStoreSetup = useRouteStoreSetup();
    routeStoreSetup.setRoutePathList(to.path);
  }
}
