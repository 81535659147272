import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocakStorageKeyType } from '@/utils/localStorageSaveKey';
import { UserModeType } from '@/utils/userModeTypeInfo';
import { cosarkAdminConnect } from '@/service/admin';
import { cosarkV3Needs } from '@/service/v3Needs';
interface UserType  {
    name?: string | '',
    userid?: string | '',
    title?: string | '',
    mobile?: string | ''
};

export const useUserInfoSetup = defineStore('user', () => {
    /**
     * 数据
     */
    const userInfo = ref(<UserType>{
        name: '',
        mobile: '',
        title: '',
        userid: ''
    });

    /**
    * 设置top数据
     */
    const setUserInfo = (e:UserType) => {
        userInfo.value = e
    };

    const getUserInfo = () => {
        return new Promise(async (resolve, reject) => {
            try {
                /**
                 * assis 用户
                 */
                if( localStorage.getItem(LocakStorageKeyType.UserModeType) === UserModeType.Assis ) {
                    const { data } = await cosarkAdminConnect.adminInfomation()
                    userInfo.value = data.data;
                    resolve(data.data)
                /**
                 * 微信用户
                 */
                } else if ( localStorage.getItem(LocakStorageKeyType.UserModeType) === UserModeType.WeiChat) {
                    const { data } = await cosarkV3Needs.v3AdminInfo();
                    userInfo.value = data.data;
                    resolve(data.data)
                /**
                 * 手机号码
                 */
                } else if ( localStorage.getItem(LocakStorageKeyType.UserModeType) === UserModeType.Mobile) {
                    const { data } = await cosarkAdminConnect.newUserCurr();
                    userInfo.value = data.data;
                    resolve(data.data)
                } 
                
            } catch (err) {
                reject(err)
            }
        })
    };

    const loginOut = () => {
        userInfo.value = {
            name: '',
            mobile: '',
            title: '',
            userid: ''
        },
        localStorage.removeItem(LocakStorageKeyType.Token)
        localStorage.removeItem(LocakStorageKeyType.Mode)
    };

    return {
        userInfo,
        setUserInfo,
        getUserInfo,
        loginOut
    };
});
