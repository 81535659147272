import { LocakStorageKeyType } from '@/utils/localStorageSaveKey';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useRouteStoreSetup = defineStore('route', () => {
  /**
   * 当前路由栈信息
   */
  const routePathList = ref<string[]>([]);
  /**
   * 设置当前路由栈信息
   */
  const setRoutePathList = (path: string) => {
    //   当没有路由栈的信息时，查看缓存
    if (!routePathList.value.length) {
      const localRoutes = localStorage.getItem(LocakStorageKeyType.Historys);
      if (localRoutes) {
        try {
          routePathList.value = JSON.parse<string[]>(localRoutes);
          //   当前刷新不在当前路由 则删除所有
          if (!routePathList.value.includes(path)) {
            routePathList.value = [];
          }
        } catch (err) {}
      }
    }
    const routeIndex = routePathList.value.indexOf(path);
    if (routeIndex > -1) {
      // 当前路由为最后者
      routePathList.value.splice(routeIndex + 1);
    } else {
      routePathList.value.push(path);
      console.log(routePathList)
    }
    // 更新缓存
    localStorage.setItem(LocakStorageKeyType.Historys, JSON.stringify(routePathList.value));
  };
  return { routePathList, setRoutePathList };
});
