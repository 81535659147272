import {
  ElButton,
  ElForm,
  ElScrollbar,
  ElFormItem,
  ElIcon,
  ElInput,
  ElOption,
  ElRow,
  ElSelect,
  ElCol,
  ElRadio,
  ElRadioGroup,
  ElTable,
  ElPagination,
  ElPopover,
  ElTooltip,
  ElTableColumn,
  ElDatePicker,
  ElConfigProvider,
  ElCascader,
  ElLoading,
  ElDialog,
  ElUpload,
  ElCheckbox,
  ElTransfer,
  ElInputNumber,
  ElTabs,
  ElTabPane,
  ElSwitch,
  ElCarousel,
  ElImage,
  ElCarouselItem,
  ElDrawer,
  ElColorPicker,
  ElAvatar,
  ElProgress,
  ElResult,
  ElSkeleton,
  ElSkeletonItem,
  ElRadioButton
} from 'element-plus';

import { App } from 'vue';

// 页面需要使用的Element组件注册
const components = [
  ElForm,
  ElPopover,
  ElRadioGroup,
  ElTooltip,
  ElDialog,
  ElConfigProvider,
  ElRadio,
  ElInput,
  ElIcon,
  ElDatePicker,
  ElScrollbar,
  ElCascader,
  ElButton,
  ElFormItem,
  ElSelect,
  ElPagination,
  ElOption,
  ElRow,
  ElCol,
  ElTable,
  ElTableColumn,
  ElUpload,
  ElCheckbox,
  ElTransfer,
  ElInputNumber,
  ElTabs,
  ElTabPane,
  ElSwitch,
  ElCarousel,
  ElImage,
  ElCarouselItem,
  ElDrawer,
  ElColorPicker,
  ElAvatar,
  ElProgress,
  ElResult,
  ElSkeleton,
  ElSkeletonItem,
  ElRadioButton
];

export const ElementUse = {
  install<T extends App<Element>>(app: T) {
    // TODO: 按需加载V-loading报错
    app.use(ElLoading)
    components.forEach((item) => app.component(item.name, item));
  },
};
