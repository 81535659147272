import { nextTick } from 'vue';
import { LocakStorageKeyType } from '@/utils/localStorageSaveKey'
import { UserInfoDetail, UserModeType } from '@/utils/userModeTypeInfo'

/**
 * 数字取整
 */
const focusEvent = (a:any) => {
    nextTick(() => {
        if(Number(a.target.value)) {
            a.target.value = Number(a.target.value)
        } else {
            a.target.value = 0;
        }
    });
}

/**
 * 获取base64
 * @returns 
 */
function getBase64(imgUrl) {
    return new Promise((resolve, reject) => {
      window.URL = window.URL || window.webkitURL;
      // 声明一个XMLHttpRequest
      const xhr = new XMLHttpRequest();
      // 获取图片
      xhr.open('get', imgUrl, true);
      xhr.responseType = 'blob';
      xhr.send();
      xhr.onload = function () {
        if (this.status === 200) {
          // 得到一个blob对象
          const blob = this.response;
          const oFileReader = new FileReader();
          oFileReader.onloadend = function (e) {
            const base64 = e.target.result;
            //拿到base64 传出结果
            resolve(base64);
          };
          oFileReader.onerror = function (e) {
            reject();
          };
          oFileReader.readAsDataURL(blob);
        }
      };
    });
  }


/**
 * base64ToBlob
 * @returns 
 */
var rImageType = /data:(image\/.+);base64,/;
function base64ToBlob(data) {
    var mimeString = '';
    var raw, uInt8Array, i, rawLength;
  
    raw = data.replace(rImageType, function (header, imageType) {
      mimeString = imageType;
  
      return '';
    });
  
    raw = atob(raw);
    rawLength = raw.length;
    uInt8Array = new Uint8Array(rawLength); // eslint-disable-line
  
    for (i = 0; i < rawLength; i += 1) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
  
    return new Blob([uInt8Array], { type: mimeString });
  }

function base64TouInt8Array(data) {
    var mimeString = '';
    var raw, uInt8Array, i, rawLength;
  
    raw = data.replace(rImageType, function (header, imageType) {
      mimeString = imageType;
  
      return '';
    });
  
    raw = atob(raw);
    rawLength = raw.length;
    uInt8Array = new Uint8Array(rawLength); // eslint-disable-line
  
    for (i = 0; i < rawLength; i += 1) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
  
    return [uInt8Array];
  }

  function base64toFile(base64Data, fileName) {
    const byteString = atob(base64Data.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const file = new File([ab], fileName, { type: 'image/png' }); // 修改图片类型
    return file;
  }

  function getUserInfo () {
    return {
      ...UserInfoDetail[localStorage.getItem(LocakStorageKeyType.UserModeType)]
    }
  }

  /**
   * @returns 
   */
  function returnObjToken () {
    const token = localStorage.getItem(LocakStorageKeyType.Token);
    let header;
    if(localStorage.getItem(LocakStorageKeyType.UserModeType) === UserModeType.Assis) {
      header = {
        'Ding-Authorization': token,
      }
    } else if (localStorage.getItem(LocakStorageKeyType.UserModeType) === UserModeType.WeiChat) {
      header = {
        'Wechat-Authorization': token
      }
    } else if (localStorage.getItem(LocakStorageKeyType.UserModeType) === UserModeType.Mobile) {
      header = {
        'App-Authorization': token
      }
    }
    return {
      header,
      token
    }
  }

  function copy (text) {
    if (navigator.clipboard) {
        // clipboard api 复制
        navigator.clipboard.writeText(text);
    } else {
        var textarea = document.createElement('textarea');
        document.body.appendChild(textarea);
        // 隐藏此输入框
        textarea.style.position = 'fixed';
        textarea.style.clip = 'rect(0 0 0 0)';
        textarea.style.top = '10px';
        // 赋值
        textarea.value = text;
        // 选中
        textarea.select();
        // 复制
        document.execCommand('copy', true);
        // 移除输入框
        document.body.removeChild(textarea);
    }
  }
  /**
   * 图片链接
   * @returns string 处理后的图片链接
   */
  function handleImageUrl (url, baseUrl = import.meta.env.VITE_IMAGE_HEAD_URL) {
    if(url?.includes('https://')){
      return url
    } else {
      return url ? `${baseUrl}${url}` : ''
    }
  }

  /**
   * 判断是否是移动端 还是 桌面端
   * @returns 
   */
  function isMobile() {
    // 判断 userAgent 中是否包含 Mobile 关键字
    return /Mobile/i.test(navigator.userAgent);
  }
export const utils = () => {
    return {
      copy,
      focusEvent,
      getBase64,
      base64ToBlob,
      base64toFile,
      base64TouInt8Array,
      getUserInfo,
      returnObjToken,
      handleImageUrl,
      isMobile
    }
}


