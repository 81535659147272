/**
 * 两种用户类型
 */
export enum UserModeType {
    Assis = 'ASSIS',
    WeiChat = 'WEICHAT',
    Mobile = 'MOBILE',
}


/**
 * 两种用户类型基础信息
 */
export const UserInfoDetail = {
    [UserModeType.Assis]: {
        userCustomeName: '我的定制', // 个人中心
        userCustomeTime: '定制时间', // 个人中心
    },
    [UserModeType.WeiChat]: {
        userCustomeName: '我的方案',
        userCustomeTime: '方案时间'
    },
    [UserModeType.Mobile]: {
        userCustomeName: '我的方案',
        userCustomeTime: '方案时间'
    },
}

/**
 * 二维码的参数
 */
export enum MiniCodeQuery {
    dev = 'develop',
    test = 'trial',
    production = 'release',
}