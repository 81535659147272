import { $axios, FileResponseType } from "@/http/api";

class Connect {
    /**
     * 用户模块
     */
    /**
     * 添加意向单
     */
    addSheet<T>(data) { 
        return $axios.Post<T, FileResponseType.Other>({
            url: "/api/eryuan/cosark.app/person/center",
            data
        });
    }
    /**
     * 意向单详细
     * @param id 
     * @param module 
     * @param sourceId 
     * @returns 
     */
    sheetDetail<T>(id, module,  sourceId) { 
        return $axios.Get<T, FileResponseType.Other>({
            url: `api/eryuan/cosark.app/person/center/${id}/${module}/${sourceId}`
        });
    }
    /**
     * 删除意向单
     * @param id 
     * @returns 
     */
    
    deleteSheet<T>(id) { 
        return $axios.Delete<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/person/center/${id}`
        });
    }

    /**
     * 列表
     * @returns 
     */
    getSheetList<T>(params) { 
        return $axios.Get<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/person/center/page`,
            params
        });
    }
    /**
     * 意向单统计
     * @returns 
     */

    getSheetStat<T>(params) { 
        return $axios.Get<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/person/center/stat`,
            params
        });
    }

    /**
     * 关联客户
     * @param id 
     * @returns 
     */
    releatedPeople<T>(id, data) { 
        return $axios.Put<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/person/center/${id}`,
            data
        });
    }

    /**
     * 客户列表
     */
    customerList<T>(params) { 
        return $axios.Get<T, FileResponseType.Other>({
            url: `/api/eryuan/assis.app/customer`,
            params
        });
    }

    /**
     * 客户联系人
     * 
     */
    customerRefContact<T>(refId) { 
        return $axios.Get<T, FileResponseType.Other>({
            url: `/api/eryuan/assis.app/customer/${refId}/contact/list`
        });
    }

    /**
     * bref详细 
     */
    brefDetail<T>(id) { 
        return $axios.Get<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/person/center/brief/${id}`
        });
    }

    /**
     * bref编辑ok 
     */
    editBrefDetail<T>(data) { 
        return $axios.Put<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/person/center/brief`,
            data
        });
    }
    
    /**
     *  申请样品
     */
    applySample<T>(data) { 
        return $axios.Post<T, FileResponseType.Other>({
            url: `api/eryuan/cosark.app/person/center/brief/apply`,
            data
        });
    }
    /**
     * 
     * @param data 
     * @returns 
     */
    quickCreateCustomer<T>(data) {
        return $axios.Post<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/person/center/customer`,
            data
        });
    }
    /**
     * 
     * @param id 
     * @param data 
     * @returns 
     */
    quickBrefCreateCustomer<T>(id, data) {
        return $axios.Post<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/person/center/brief/${id}/customer`,
            data
        });
    }
    /**
     * 
     * @returns 
     */
    adminInfomation<T>() { 
        return $axios.Get<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/home/user`
        });
    }
    /**
     * 职位
     * @returns 
     */
    adminPosition<T>() { 
        return $axios.Get<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/person/center/customer/contact/position`
        });
    }

    /**
     * 用户
     * @returns 
     */
    newUserCurr<T>() { 
        return $axios.Get<T, FileResponseType.Other>({
            url: `/api/eryuan/cosark.app/user/curr`
        });
    }
}

/**
 * 用户模块
 */
export const cosarkAdminConnect = new Connect();
