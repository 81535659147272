import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import { createPinia } from "pinia"
import './assets/scss/root.scss'
import 'animate.css/animate.min.css'
import 'viewerjs/dist/viewer.css';
import { ElementUse } from './utils/element-use';;

createApp(App).use(createPinia()).use(router).use(ElementUse).mount('#app')
